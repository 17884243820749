import React, {
  useState,
} from 'react'
import update from 'immutability-helper'
import { throttle } from 'lodash'
import {
  Card,
  TextField,
  Popover,
  FormLayout,
  ColorPicker,
  RangeSlider,
  ButtonGroup,
  Button,
  Stack
} from "@shopify/polaris"
function hslToHex(h, s, l) {
  l /= 100;
  const a = s * Math.min(l, 1 - l) / 100;
  const f = n => {
    const k = (n + h / 30) % 12;
    const color = l - a * Math.max(Math.min(k - 3, 9 - k, 1), -1);
    return Math.round(255 * color).toString(16).padStart(2, '0');   // convert to Hex and prefix "0" if needed
  };
  return `#${f(0)}${f(8)}${f(4)}`;
}
function ButtonsTab(props) {
  const { state, setState } = props;
  const [ type, setType ] = useState('cart')
  const [ctaBackgroundColorPopoverVisible, setCtaBackgroundColorPopoverVisible] = useState(false)
  const [ctaBackgroundColorHsl, setCtaBackgroundColorHsl] = useState({
    hue: 131,
    saturation: 86,
    brightness: 34,
    alpha: 1,
  })
  const [ctaButtonTextColorPopoverVisible, setCtaButtonTextColorPopoverVisible] = useState(false)
  const [ctaButtonTextColorHsl, setCtaButtonTextColorHsl] = useState({
    hue: 0,
    saturation: 0,
    brightness: 100,
    alpha: 1,
  })
  const [checkoutButtonTextColorPopoverVisible, setCheckoutButtonTextColorPopoverVisible] = useState(false)
  const [checkoutButtonTextColorHsl, setCheckoutButtonTextColorHsl] = useState({
    hue: 131,
    saturation: 86,
    brightness: 34,
    alpha: 1,
  })

  const ctaBackgroundColorActivatorButton = (
    <TextField
      label="Button color"
      onFocus={() => setCtaBackgroundColorPopoverVisible(true)}
      value={state.ctaBackgroundColor}
      onChange={(val) => setState(update(state, { ctaBackgroundColor: { $set: val }})) }
      connectedRight={
        <div
          style={{
            borderRadius: 5,
            cursor: 'pointer',
            height: '100%',
            width: 40,
            backgroundColor: state.ctaBackgroundColor
          }}
          onClick={() => setCtaBackgroundColorPopoverVisible(true)}
        />
      }
    />
  )

  const onCtaBackgroundColorChange = (updatedColor) => {
    const color = {
      hue: updatedColor.hue || ctaBackgroundColorHsl.hue || 0,
      saturation: updatedColor.saturation || ctaBackgroundColorHsl.saturation || 0,
      brightness: updatedColor.brightness || ctaBackgroundColorHsl.brightness || 0,
      alpha: 1,
    }
    const hex = hslToHex(
      color.hue,
      color.saturation * 100,
      color.brightness * 100,
    )

    console.log('Color changed: ', color, hex)

    setCtaBackgroundColorHsl(color)
    setState(update(state, { ctaBackgroundColor: { $set: hex }}))
  }
  const onCtaBackgroundColorChangeThrottled = throttle(onCtaBackgroundColorChange, 250)

  const ctaButtonTextColorActivatorButton = (
    <div style={{
      borderRadius: 5,
      cursor: 'pointer',
      height: 36,
      width: 40,
      backgroundColor: state.ctaButtonTextColor
    }}
         onClick={() => setCtaButtonTextColorPopoverVisible(true)}>
    </div>
  )
  const onCtaButtonTextColorChange = (updatedColor) => {
    const color = {
      hue: updatedColor.hue || ctaButtonTextColorHsl.hue || 0,
      saturation: updatedColor.saturation || ctaButtonTextColorHsl.saturation || 0,
      brightness: updatedColor.brightness || ctaButtonTextColorHsl.brightness || 0,
      alpha: 1,
    }
    const hex = hslToHex(
      color.hue,
      color.saturation * 100,
      color.brightness * 100,
    )

    console.log('Color changed: ', color, hex)

    setCtaButtonTextColorHsl(color)
    setState(update(state, { ctaButtonTextColor: { $set: hex }}))
  }
  const onCtaButtonTextColorChangeThrottled = throttle(onCtaButtonTextColorChange, 250)


  const checkoutButtonTextColorActivatorButton = (
    <div style={{
      borderRadius: 5,
      cursor: 'pointer',
      height: 36,
      width: 40,
      backgroundColor: state.checkoutTextColor
    }}
         onClick={() => setCheckoutButtonTextColorPopoverVisible(true)}>
    </div>
  )
  const onCheckoutButtonTextColorChange = (updatedColor) => {
    const color = {
      hue: updatedColor.hue || checkoutButtonTextColorHsl.hue || 0,
      saturation: updatedColor.saturation || checkoutButtonTextColorHsl.saturation || 0,
      brightness: updatedColor.brightness || checkoutButtonTextColorHsl.brightness || 0,
      alpha: 1,
    }
    const hex = hslToHex(
      color.hue,
      color.saturation * 100,
      color.brightness * 100,
    )

    console.log('Color changed: ', color, hex)

    setCheckoutButtonTextColorHsl(color)
    setState(update(state, { checkoutTextColor: { $set: hex }}))
  }
  const onCheckoutButtonTextColorChangeThrottled = throttle(onCheckoutButtonTextColorChange, 250)

  const suffixStyles = {
    minWidth: '24px',
    textAlign: 'right',
  };
  console.log("type:", type)
  return (
    <FormLayout>
      <ButtonGroup segmented fullWidth={true}>
        <Button pressed={"cart" === type} onClick={() => setType('cart')}>
          Add to cart & variant
        </Button>
        <Button pressed={"checkout" === type} onClick={() => setType('checkout')}>
          Checkout
        </Button>
      </ButtonGroup>
      {"cart" === type ?
        <div>
          <Stack wrap={false} alignment="trailing">
            <Stack.Item fill>
              <TextField
                label="Button text"
                placeholder="Add to cart"
                onChange={(val) => setState(update(state, { cta: { $set: val }})) }
                value={state.cta}
              />
            </Stack.Item>
            <Stack.Item>
              <Popover
                active={ctaButtonTextColorPopoverVisible}
                activator={ctaButtonTextColorActivatorButton}
                onClose={() => setCtaButtonTextColorPopoverVisible(false)}
                sectioned
              >
                <FormLayout>
                  <TextField
                    label="Button text color"
                    value={state.ctaButtonTextColor}
                    onChange={(val) => setState(update(state, { ctaButtonTextColor: { $set: val }})) }
                  />
                  <ColorPicker
                    color={ctaButtonTextColorHsl}
                    onChange={onCtaButtonTextColorChangeThrottled}
                  />
                </FormLayout>
              </Popover>
            </Stack.Item>
          </Stack>
          <Popover
            active={ctaBackgroundColorPopoverVisible}
            activator={ctaBackgroundColorActivatorButton}
            onClose={() => setCtaBackgroundColorPopoverVisible(false)}
          >
            <ColorPicker
              color={ctaBackgroundColorHsl}
              onChange={onCtaBackgroundColorChangeThrottled}
            />
          </Popover>
          <RangeSlider
            label="Button border radius"
            prefix={<p>px</p>}
            suffix={<p style={suffixStyles}>{state.buttonBorderRadius}</p>}
            value={state.buttonBorderRadius}
            onChange={(val) => setState(update(state, { buttonBorderRadius: { $set: val }})) }
            output
          />
          <RangeSlider
            label="Button height"
            prefix={<p>px</p>}
            suffix={<p style={suffixStyles}>{state.buttonHeight}</p>}
            value={state.buttonHeight}
            onChange={(val) => setState(update(state, { buttonHeight: { $set: val }}))}
            output
          />
        </div>
        :
        <div>
          <Stack wrap={false} alignment="trailing">
            <Stack.Item fill>
              <TextField
                label="Button text"
                placeholder="Continue to checkout"
                onChange={(val) => setState(update(state, { checkoutText: { $set: val }})) }
                value={state.checkoutText}
              />
            </Stack.Item>
            <Stack.Item>
              <Popover
                active={checkoutButtonTextColorPopoverVisible}
                activator={checkoutButtonTextColorActivatorButton}
                onClose={() => setCheckoutButtonTextColorPopoverVisible(false)}
                sectioned
              >
                <FormLayout>
                  <TextField
                    label="Button text color"
                    value={state.checkoutTextColor}
                    onChange={(val) => setState(update(state, { checkoutTextColor: { $set: val }})) }
                  />
                  <ColorPicker
                    color={checkoutButtonTextColorHsl}
                    onChange={onCheckoutButtonTextColorChangeThrottled}
                  />
                </FormLayout>
              </Popover>
            </Stack.Item>
          </Stack>
          
        </div>
      }
    </FormLayout>
  )
}

export default ButtonsTab;